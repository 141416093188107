.coursesCard {
  padding: 50px 0;
}
.coursesCard .items {
  background-color: #fff;
  padding: 30px;
  text-align: center;
}
.coursesCard .img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #d52941;
  padding: 15px;
}
.coursesCard img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.coursesCard .text {
  margin-left: 15px;
  text-align: left;
}
.coursesCard .text h1 {
  font-size: 30px;
  font-weight: 500;
  line-height: 45px;
}
.coursesCard .rate {
  margin: 20px 0;
  color: #d52941;
}
.coursesCard .rate i {
  font-size: 13px;
  margin-right: 5px;
}
.coursesCard .details .dimg img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 20px;
}
.coursesCard .details .box {
  display: flex;
  align-items: center;
  color: grey;
}
.coursesCard .details span {
  color: #d52941;
  font-weight: 500;
  font-size: 14px;
}
.coursesCard .price {
  margin: 30px 0;
  background-color: #f8f8f8;
  padding: 10px;
}
.coursesCard h3 {
  font-weight: 500;
  color: #d52941;
}
/*-------------online---------*/
.online {
  text-align: center;
}

.online .content {
  align-items: center;
}

.online .container {
  display: flex;
  justify-content: space-between;
}

.online .img {
  width: 280px;
  height: 280px;
  margin: auto;
  position: relative;
}
.online img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.online h1 {
  font-weight: 500;
  font-size: 30px;
  margin: 20px 0;
  line-height: 30px;
}
.online span {
  background-color: #f8f8f8;
  padding: 5px 20px;
  font-weight: 500;
  font-size: 30px;
  color: #d52941;
  border-radius: 5px;
}
.online .box {
  box-shadow: 0 5px 25px -2px rgb(0 0 0 / 6%);
  background-color: #fff;
  padding: 40px;
  transition: 0.5s;
  width: 400px;
  box-sizing: border-box;
  margin: 20px;
}

.online .box:hover {
  background-color: #d52941;
  cursor: pointer;
}

.online .course-link {
  display: block;
  text-decoration: none;
  color: inherit; /* Inherit the text color from the parent */
}

.online .box:hover h1 {
  color: #fff;
}
/*-------------online---------*/
/* ... (your existing styles) */

/* Add a media query for larger screens */
@media screen and (min-width: 769px) {
  .online .box {
    box-shadow: 0 5px 25px -2px rgb(0 0 0 / 6%);
    background-color: #fff;
    padding: 20px; /* Adjust padding as needed */
    transition: 0.5s;
    width: 300px;
    box-sizing: border-box;
    margin: 20px;
  }

  .online .course-link {
    display: block;
    text-decoration: none;
    color: inherit; /* Inherit the text color from the parent */
  }

  
  .online .box:hover {
    background-color: #d52941;
    cursor: pointer;
  }
  
  .online .course-link {
    display: block;
    text-decoration: none;
    color: inherit; /* Inherit the text color from the parent */
  }
  
  .online .box:hover h1 {
    color: #fff;
  }


  .online .img {
    width: 250px;
    height: 280px;
    margin: auto;
    position: relative;
  }
  .online img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .online .content {
    display: flex;
    flex-wrap: wrap; /* Allow cards to wrap to the next line */
    justify-content: space-between;
    margin: -20px; /* Counteract the margin on individual cards */
  }
}

@media screen and (max-width: 768px) {
}
