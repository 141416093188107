.aboutHome {
  height: auto;
}
.aboutHome #heading {
  text-align: left;
  padding: 0;
  color: #ffffff;
}
.aboutHome .left img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.aboutHome .right {
  padding: 80px 50px;
}
.aboutHome .items {
  margin-top: 50px;
}
.aboutHome .item {
  background-color: #ffffff;
  margin-top: 30px;
  padding: 20px;
  transition: 0.5s;
}
.aboutHome .img {
  width: 200px;
}
.aboutHome img {
  width: 70px;
  height: 70px;
}
.aboutHome .item h2 {
  font-size: 20px;
  margin-bottom: 15px;
}
.aboutHome .item p {
  color: #999999;
}
.aboutHome .item:hover {
  background-color: #d52941;
  color: #fff;
  cursor: pointer;
  box-shadow: 0 5px 25px -2px rgb(0 0 0 / 6%);
}
.aboutHome .item:hover p {
  color: #fff;
}




/* Main container */
.additional-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 50px auto;
  max-width: 1200px;
}

/* Individual card */
.card {
  width: calc(30% - 20px);
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  text-align: left;
  transition: background-color 0.3s, color 0.3s;
}

/* Hover effect for the card */
.card:hover {
  background-color: #d52941;
  color: #fff;
}

/* Heading inside card */
.blue-heading {
  color: #d52941;
  margin-bottom: 10px;
  text-align: center;
}

/* Hover effect for the heading when hovering over the card */
.card:hover .blue-heading {
  color: #ffffff;
}


/* Text inside card */
.card-inner {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.card p {
  flex-grow: 1;
}

/* List inside card */
.bullet-list {
  list-style-type: disc;
  padding-left: 20px;
}

.bullet-list li {
  margin-bottom: 5px;
}




/* Responsive styles */
@media screen and (max-width: 1024px) {
  .card {
    width: calc(50% - 20px); /* Adjusted width to fit two cards in a row with spacing */
  }
}

@media screen and (max-width: 768px) {
  .card {
    width: 100%; /* Full width on smaller screens */
    margin-bottom: 20px; /* Add margin between cards */
  }
}







/*----------awrapper------------*/
.awrapper {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 30vh;
  color: #fff;
}
.awrapper .box {
  padding: 70px 0;
}
.awrapper img {
  margin-right: 30px;
}
.awrapper h1 {
  font-size: 50px;
}
.awrapper h3 {
  font-size: 20px;
  font-weight: 500;
}
/*----------awrapper------------*/
@media screen and (max-width: 768px) {
  .aboutHome .container {
    flex-direction: column-reverse;
  }
  .aboutHome .row {
    width: 100%;
  }
  .awrapper {
    height: 50vh;
  }
}
