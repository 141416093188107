#video-container {
  position: relative;
  width: 100%;
}

.home-page {
  /* Set background properties */
  background-image: url("../../../assets/images/home-bg-7.jpeg"); /* Replace with your image path */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  /* opacity: 0.1; */
  /* width: f; */

  /* Other styles like background position, repeat, etc., can be added as needed */
}

#text-overlay {
  position: absolute;
  top: 55%;
  left: 38%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 24px;
  z-index: 1;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}
