@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  font-family: "Roboto", sans-serif;
  background-color: #f8f8f8;
}
/*------------global-----------*/
.container {
  max-width: 85%;
  margin: auto;
}
.flexSB {
  display: flex;
  justify-content: space-between;
}
.icon {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.3);
  color: hsl(175, 71%, 41%);
  transition: 0.5s;
}
.icon:hover {
  cursor: pointer;
  background-color: #d52941;
  color: #fff;
}
a {
  text-decoration: none;
}
li {
  list-style-type: none;
}
.row {
  width: 50%;
}
button {
  padding: 17px 30px;
  background-color: #fff;
  color: #d52941;
  font-weight: 600;
  border: none;
  margin: 30px 10px 0 0;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: 0 24px 36px -11px rgb(0 0 0 / 9%);
  transition: 0.5s;
}
button i {
  margin-left: 20px;
}
button:hover {
  box-shadow: none;
}
.primary-btn {
  background-color: #d52941;
  color: #fff;
}

/*------------global-----------*/
#heading {
  text-align: center;
  padding: 40px 0;
}
#heading h3 {
  font-weight: 600;
  letter-spacing: 1px;
  color: #d52941;
  text-transform: uppercase;
}
#heading h1 {
  font-size: 45px;
  margin: 20px 0;
  text-transform: capitalize;
}
p {
  line-height: 30px;
  font-size: 18px;
}

.back {
  background-size: cover;
  background-attachment: fixed;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100vh;
  padding-top: 30%;
  padding-right: 50px;
  color: #fff;
  text-align: center;
}
.back h1 {
  font-size: 100px;
  font-weight: 400;
}
.back h2 {
  font-weight: 500;
  font-size: 17px;
  text-transform: uppercase;
}
.margin {
  margin-top: 40.3%;
}
.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
}
.flex {
  display: flex;
}
.grid2 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}
.outline-btn {
  margin: 0;
  box-shadow: none;
  border: 2px solid #d52941;
  width: 100%;
  transition: 0.5s;
}
.outline-btn:hover {
  background-color: #d52941;
  color: #fff;
}
.grid3 {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 10px;
}
.padding {
  padding: 80px 0;
}
.shadow {
  box-shadow: 0 5px 25px -2px rgb(0 0 0 / 6%);
  background-color: #fff;
}
@media screen and (max-width: 768px) {
  .grid2,
  .grid3,
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .back {
    background-position: center;
    padding-top: 50%;
    height: 80vh;
  }
}
