.contacts iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
  .contacts .right {
    padding: 50px;
  }
  .contacts h1 {
    font-size: 30px;
    font-weight: 400;
  }
  .contacts p {
    color: grey;
  }
  .contacts .items {
    margin: 30px 0;
  }
  .contacts .box p {
    font-size: 17px;
    margin-top: 10px;
  }
  .contacts textarea,
  .contacts input {
    width: 100%;
    padding: 15px;
    margin-bottom: 15px;
    outline: none;
    border: 1px solid grey;
  }
  .contacts form select {
    width: 100%;
    padding: 15px;
    margin-bottom: 15px;
  }
  form .flexSB input:nth-child(1) {
    margin-right: 10px;
  }
  .contacts h3 {
    margin-top: 20px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .contacts span {
    color: #d52941;
    font-weight: 600;
    font-size: 15px;
    word-spacing: 5px;
  }
  
  
  @media screen and (max-width: 768px) {
    .contacts .container {
      flex-direction: column;
    }
    .contacts iframe {
      height: 50vh;
    }
    .contacts .row {
      width: 100%;
    }
  }
  