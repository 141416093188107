/* Documents.css */

.documents-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1rem;
    padding: 1rem;
  }
  
  .document-card {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 1rem;
    text-align: center;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .document-icon {
    color: #cc0000;
    margin-bottom: 0.5rem;
  }
  
  h3 {
    color: #333;
    margin-bottom: 1rem;
  }
  
  .download-button {
    background-color: #f2f2f2;
    color: #333;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    transition: background-color 0.2s;
  }
  
  .download-button:hover,
  .download-button:focus {
    background-color: #e2e2e2;
  }
  
  .download-icon {
    font-size: 0.8rem;
  }

  /* Documents.css */

.documents-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
  }
  
  .document-card {
    width: 200px; /* Adjust the width as necessary */
    padding: 1rem;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background: #fff;
  }
  
  .document-icon {
    color: #d35e5e;
    margin-bottom: 0.5rem;
  }
  
  .document-card h3 {
    margin-bottom: 1rem;
  }
  
  .download-button {
    background-color: #f0f0f0;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    cursor: pointer;
    outline: none;
  }
  
  .download-icon {
    margin-right: 0.5rem;
  }
  
  .download-button:hover {
    background-color: #e0e0e0;
  }
  
  /* Add more styles as necessary */
  
  